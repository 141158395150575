import React, { useState, useEffect } from "react";
import { useCallback } from 'react';
//import { toast } from 'react-toastify';

import { useNavigate } from "react-router-dom";
import { UserAuth } from "../contexts/AuthContext";
import useFetchUserContext from "../hooks/use-userInformation-context";
import axios from "axios";
import { FaPlus } from "react-icons/fa";
import { PeoplePagination } from "./people-pagination.js";
import { CommentsModal } from "./comments-modal.js";
import { render } from "@testing-library/react";

const PeopleResultsForm = (props) => {

  const navigate = useNavigate();

  const { CurrentUser } = useFetchUserContext();
  const { dataAPI, APIdata, logout, ThisCanditate } = UserAuth()

  console.log(APIdata)

  // common

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategories, setSelectedCategories] = useState("");
  const [commentsModal, setCommentsModal] = useState(false);
  const [personModal, setPersonModal] = useState()

  // dropdowns
  const [salaryRange, setSalaryRange] = useState("")
  const [pensionRange, setPensionRange] = useState("")
  const [bonusRange, setBonusRange] = useState("")

  //filters
  const [filters, setFilters] = useState()

  //categories
  
  const categories_api = {
    "Job Title": "current_latest_job_title",
    "Last Name": "last_name",
    Screening: "screening",
    Interview: "interview",
  }

  /* pagination */

  const [currentPage, setCurrentPage] = useState(1)
  
  const itemsPerPage = 10

  let startIndex = (currentPage - 1) * itemsPerPage
  let endIndex = startIndex + itemsPerPage > APIdata.length ? APIdata.length : startIndex + itemsPerPage

  let totalPages = Math.ceil(APIdata.length / itemsPerPage)

  //display data

  let displayData = APIdata.flatMap((user) => {

    if (user.assignments) {
      return user.assignments.map((assignment) => ({
        value: `${user.first_name} ${user.last_name}`,
        name: `${user.first_name} ${user.last_name}`,
        email: user.email,
        assignment_name: user.assignment_name,
        applied_company: user.current_latest_employer,
        phone_number: user.phone_number,
        assignment: assignment,
        progress: assignment.progress_status,
        id: user.id,
        salary: numberWithCommas(user.future_salary),
        bonus: numberWithCommas(user.future_bonus),
        pension: numberWithCommas(user.future_pension),
        currency: user.salary_currency,
        industry: user.industry,
        current_latest_job_title: user.current_latest_job_title,
        job_function: user.job_function,
        internal_comments: user.internal_comments,
      }))
    }

    return {
      value: `${user.first_name} ${user.last_name}`,
      name: `${user.first_name} ${user.last_name}`,
      email: user.email,
      assignment_name: user.assignment_name,
      applied_company: user.current_latest_employer,
      phone_number: user.phone_number,
      assignment: {
        assignment_name: user.assignment_name,
        candidate_internal_comments: user.candidate_internal_comments,
        external_ref: user.external_ref,
        job_level: user.job_level,
        notes: user.notes,
        progress_notes: user.progress_notes,
        progress_status: user.progress_status,
      },
      progress: user.progress_status,
      id: user.id,
      salary: numberWithCommas(user.future_salary),
      bonus: numberWithCommas(user.future_bonus),
      pension: numberWithCommas(user.future_pension),
      currency: user.salary_currency,
      industry: user.industry,
      job_function: user.job_function,
      internal_comments: user.internal_comments,
    }
  }).sort((a,b) => (a.progress === '6. Shortlisted') ? -1 : 1)

  // handlers

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
    } catch (e) {
      console.log(e.message);
    }
  }

  const Handleback = async () => {
    navigate("/search");
  }

  const handleChange = (e) => {
    const searchTerm = e.target.value;
    return

    const handler = setTimeout(() => {
      setSearchTerm(searchTerm);
      console.log("Debounced Search Term Set:", searchTerm);
      if (selectedCategories)
        FetchByCategories(categories_api[selectedCategories], searchTerm);
    }, 1300);

    return () => {
      clearTimeout(handler);
    }
    //setSearchTerm(searchTerm)
  }

  const handlePaginationChange = (e) => {
    let index = e.target.getAttribute("index")
    console.log("index" + index)

    if (index >= 0 || index < totalPages) {
      setCurrentPage(parseInt(index))
    }
  }

  const handleClick = (
    email,
    external_ref,
    progress_notes,
    progress_status,
    assignment_name,
    candidate_internal_comments,
    interview,
    screening,
    job_level,
    id
  ) => {
    ThisCanditate({
      external_ref: external_ref,
      progress_notes: progress_notes,
      progress_status: progress_status,
      assignment_name: assignment_name,
      candidate_internal_comments: candidate_internal_comments,
      interview: interview,
      screening: screening,
      job_level: job_level,
    });
    CurrentUser(id);
    fetchID(id).then(() => {
      navigate("/results");
    });
  }

  /*filters*/

  const handleSalaryRangeChange = (e) => {
    const value = e.target.value

    if(value === 'clear_filter'){
      console.log("inside clear filters")
     

      setFilters((prevFilters) => {
        const { future_salary, ...otherFilters } = prevFilters;  // Destructure to remove `future_salary`
        return otherFilters;  // Return the new filters object without `future_salary`
      });

      setSalaryRange("")
      return
    }

    setSalaryRange(value)

    let minSalary = ""
    let maxSalary = ""

    if (value.includes("-")) {
      minSalary = value.split("-")[0]
      maxSalary = value.split("-")[1]

      setFilters((prevFilters) => ({
        ...prevFilters,
        future_salary: {min_value: minSalary, max_value: maxSalary},
      }))
      return;
    }
  }

  const handlePensionRangeChange = (e) => {
    const value = e.target.value

    if(value === 'clear_filter'){

      setFilters((prevFilters) => {
        const { future_pension, ...otherFilters } = prevFilters;  // Destructure to remove `future_salary`
        return otherFilters;  // Return the new filters object without `future_salary`
      });

      setPensionRange("")
      return
    }

    setPensionRange(value)

    let minPension = "";
    let maxPension = "";

    if (value.includes("-")) {
      minPension = value.split("-")[0]
      maxPension = value.split("-")[1]

      setFilters((prevFilters) => ({
        ...prevFilters,
        future_pension: {min_value: minPension, max_value: maxPension},
      }));

      //FetchByRenumeration("future_pension", minPension, maxPension);

      return;
    }

    //FetchByRenumeration("future_pension", minPension, maxPension);
  }

  const handleBonusRangeChange = (e) => {
    const value = e.target.value

    if(value === 'clear_filter'){

      setFilters((prevFilters) => {
        const { future_bonus, ...otherFilters } = prevFilters;  // Destructure to remove `future_bonus`
        return otherFilters;  // Return the new filters object without `future_bonus`
      });

      setBonusRange("")
      return
    }

    setBonusRange(value)

    let minBonus = ""
    let maxBonus = ""

    if (value.includes("-")) {
      minBonus = value.split("-")[0]
      maxBonus = value.split("-")[1]

      setFilters((prevFilters) => ({
        ...prevFilters,
        future_bonus: {min_value: minBonus, max_value: maxBonus},
      }))

      //FetchByRenumeration("future_bonus", minBonus, maxBonus);

      return
    }

    //FetchByRenumeration("future_bonus", minBonus, maxBonus)
  }

  const handleFilterChange = (e) => {
    const { name, value } = e.target

    

    if(value === 'clear_filter'){

      console.log(name)
      console.log(value)

      setFilters((prevFilters) => {
        const { [name]: _, ...otherFilters } = prevFilters;
        return otherFilters; 
      });

      return
    }

    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }))
  }

  /*end of filters*/

  // end handlers

  // fetch data with and iwthout filters from api

  useEffect(() => {
    // Only run the effect if `filters` is defined and not empty
    if (!filters || filters.length === 0) return;

    console.log(filters)
  
    const fetchByFilters = async () => {
      try {
        const response = await axios.post(
          `https://europe-west3-compass-gcp.cloudfunctions.net/Compass_Api_no_auth/api/bigquery/fetchByFilters`, 
          filters
        )
  
        const data = response.data;
  
        console.log(data);
  
        if (data.length !== 0) {
          console.log("setting data API");
          dataAPI(data);
        } else {
          dataAPI([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchByFilters();
  }, [filters])

  /* end of pagination code */

  // utils

  function numberWithCommas(x) {
    if (!x) return "";
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(",");
  }

  const fetchID = async (value) => {
    try {
      const response = await axios.get(
        `https://europe-west3-compass-gcp.cloudfunctions.net/Compass_Api_no_auth/api/bigquery/fetchFromID?id=${value}`
      );
      const data = response.data;
      dataAPI(data);
    } catch (error) {
      console.log(error);
    }
  }

  const toggleCategorySelection = (item) => {
    let itemSelected = item.target.textContent;

    if (selectedCategories.includes(itemSelected)) {
      // If item is already selected, remove it
      setSelectedCategories("");
    } else {
      // If item is not selected, add it
      setSelectedCategories(itemSelected);
    }
  }

  const CreateCandidateOnCLick = () => {
    navigate("/canditate");
  }

  const FetchByCategories = async (category, searchValue) => {
    console.log("calling api");
    //return

    const response = await axios.get(
      `https://europe-west3-compass-gcp.cloudfunctions.net/Compass_Api_no_auth/api/bigquery/fetchByCategories?category=${category}&value=${searchValue}`
    );
    const data = response.data;

    console.log(data);

    if (data.length !== 0) {
      console.log("setting data API");
      dataAPI(data);
    } else {
      dataAPI([]);
    }
  }

  /* toggle comment modal */

  const toggleCommentsModal = (person) => {
    commentsModal ? setCommentsModal(false) : setCommentsModal(true);

    personModal !== undefined
      ? setPersonModal(undefined)
      : setPersonModal(person);
  }

  /* end of toggle comment modal */

  

  return (
    <div className="flex flex-col max-w-6xl min-w-4x1 mx-auto PeopleResults-container pb-4">
      {commentsModal ? (
        <CommentsModal
          person={personModal}
          toggleCommentsModal={toggleCommentsModal}
        />
      ) : (
        ""
      )}

      <div className=" bg-white rounded-lg shadow-md">
        <div className="grid grid-cols-12 bg-gray-50 py-4 px-4 border-gray-100">
          <div className="col-span-9">
            <label
              for="default-search"
              class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
            >
              Search
            </label>
            <div class="relative">
              <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  class="w-4 h-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </div>
              <input
                type="search"
                value={searchTerm}
                onChange={handleChange}
                id="default-search"
                class="block w-full py-4 px-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
                placeholder="Search by Keywords..."
                required
              />
            </div>
          </div>
          <div className="flex justify-end col-span-3 items-end pl-2">
            {/* Added "flex justify-end" */}
            <button
              onClick={CreateCandidateOnCLick}
              className="bg-green-400 hover:bg-emerald-400 transition text-white py-3.5 px-4 rounded inline-flex items-center w-full justify-center"
            >
              <FaPlus className="fill-current w-4 h-4 mr-2" />
              <span>Create Candidate</span>
            </button>
          </div>
        </div>

        {/*
        <div className="flex justify-left mb-5 gap-5 ml-5">
          <div className="flex flex-col">
            <p>Search the Invenias Database:</p>
            <div className="text-[9px] text-gray-500">
              Click on one of the categories and search above
            </div>
          </div>

          {categories.map((category, index) => (
            <button
              onClick={toggleCategorySelection}
              className={`${
                selectedCategories?.includes(category) ? "active" : ""
              } text-[10px] border border-black bg-transparent mb-[5px] rounded-[5px] transition-all duration-200 ease-in-out cursor-pointer py-[5px] px-[10px] hover:shadow-[3px_3px_0px_0px_rgba(0,0,0)]`}
              key={category}
            >
              {category}
            </button>
          ))}
        </div>
        */}
        {window.location.href.includes("Assignment") ? (
        ""
      ) : (
        <div className="flex justify-center flex-wrap mb-5 gap-5 ml-5">
        <div className="flex flex-col items-center">
          <p className="text-[10px]">Salary</p>

          <select
            id="product-category"
            value={salaryRange}
            onChange={handleSalaryRangeChange}
            class="px-4 py-2 bg-white border border-gray-300 cursor-pointer rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-700 text-[10px]"
          >
            <option value="" disabled selected>
              Select a Salary Range
            </option>
            <option value="20000-30000">20.000 - 30.000</option>
            <option value="30000-40000">30.000 - 40.000</option>
            <option value="40000-50000">40.000 - 50.000</option>
            <option value="60000-70000">60.000 - 70.000</option>
            <option value="80000-250000">80.000 +</option>
            <option value="clear_filter">Clear Filter</option>
          </select>
        </div>

        <div className="flex flex-col items-center">
          <p className="text-[10px]">Pension</p>

          <select
            id="product-category"
            value={pensionRange}
            onChange={handlePensionRangeChange}
            class="px-4 py-2 bg-white border border-gray-300 cursor-pointer rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-700 text-[10px]"
          >
            <option value="" disabled selected>
              Select a Pension Range
            </option>
            <option value="0-5">0% - 5%</option>
            <option value="5-10">5% - 10%</option>
            <option value="10-15">10% - 15%</option>
            <option value="15-20">15% - 20%</option>
            <option value="20-100">20% or more</option>
            <option value="clear_filter">Clear Filter</option>
          </select>
        </div>

        <div className="flex flex-col items-center">
          <p className="text-[10px]">Bonus</p>

          <select
            id="product-category"
            value={bonusRange}
            onChange={handleBonusRangeChange}
            class="px-4 py-2 bg-white border border-gray-300 cursor-pointer rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-700 text-[10px]"
          >
            <option value="" disabled selected>
              Select a Bonus Range
            </option>
            <option value="0-50000">0 - 50.000</option>
            <option value="50000-100000">50.000 - 100.000</option>
            <option value="100000-500000">100.000 - 500.000</option>
            <option value="500000-1000000">500.000 - 1.000.000</option>
            <option value="1000000-10000000">1.000.000 - 10.000.000</option>
            <option value="10000000-90000000">10.000.000 or more</option>
            <option value="clear_filter">Clear Filter</option>
          </select>
        </div>

        <div className="flex flex-col items-center">
          <p className="text-[10px]">Industry</p>

          <select
            id="product-category"
            name="industry"
            value={!filters?.industry ? "" : filters?.industry}
            onChange={handleFilterChange}
            class="px-4 py-2 bg-white border border-gray-300 cursor-pointer rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-700 text-[10px]"
          >
            <option value="" disabled selected>
              Select an Industry
            </option>
            <option value="Food and beverages">Food and beverages</option>
            <option value="Consumer Products">Consumer Products</option>
            <option value="Pharmaceuticals and Biotechnology">
              Pharmaceuticals and Biotechnology
            </option>
            <option value="clear_filter">Clear Filter</option>
          </select>
        </div>

        <div className="flex flex-col items-center">
          <p className="text-[10px]">Job Function</p>

          <select
            id="product-category"
            name="job_function"
            value={!filters?.job_function ? "" : filters?.job_function}
            onChange={handleFilterChange}
            class="px-4 py-2 bg-white border border-gray-300 cursor-pointer rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-gray-700 text-[10px]"
          >
            <option value="" disabled selected>
              Select a Job Function
            </option>
            <option value="Leader">Leader</option>
            <option value="Specialist">Specialist</option>
            <option value="Manager">Manager</option>
            <option value="clear_filter">Clear Filter</option>
          </select>
        </div>
      </div>
      )}

        {displayData.length !== 0 ? (
          <div className="bg-white rounded-lg shadow-md overflow-scroll scrollbar-hide">
            <table className="table-auto w-full PeopleResults">
              <thead className="text-gray-700 bg-[#1EADE1] text-white">
                <tr>
                  <th className="px-4 py-1 text-xs font-bold">Name</th>
                  {/* <th className="px-4 py-1 text-xs">Assignments</th> 
                  <th className="px-4 py-1 text-xs">Phonenumber</th>
                  <th className="px-4 py-1 text-xs">Salary</th>

                  <th className="px-4 py-1 text-xs">Currency</th>
                  */}
                  <th className="px-4 py-1 text-xs">External Ref.</th>
                  <th className="px-4 py-1 text-xs">Assignment Name</th>
                  <th className="px-4 py-1 text-xs">Job Title</th>
                  <th className="px-4 py-1 text-xs">Company</th>
                  <th className="px-4 py-4 text-xs">Progress</th>
                  <th className="px-4 py-1 text-xs">Email</th>

                  

                  <th className="px-4 py-1"></th>

                  <th className="px-4 py-1"></th>
                </tr>
              </thead>
              <tbody>
                {displayData.map((person, index) => (
                  <tr
                    key={index}
                    className={index % 2 === 0 ? "bg-gray-50" : ""}
                  >
                    <td className="text-xxs">{person.name}</td>

                    <td className="text-xxs">
                      {person.assignment.external_ref}
                    </td>

                    <td className="text-xxs">
                      {person.assignment.assignment_name}
                    </td>
                    <td className="text-xxs">
                      {person.current_latest_job_title}
                    </td>

                    <td className="text-xxs">{person.applied_company}</td>

                    <td className="text-xs">
                      {person.assignment.progress_status ===
                        "1. Target Candidate" ||
                      person.assignment.progress_status ===
                        "2. Application Received" ||
                      person.assignment.progress_status === "3. Left Message" ||
                      person.assignment.progress_status ===
                        "4. In Discussion" ||
                      person.assignment.progress_status ===
                        "5. Consultant Interview" ||
                      person.assignment.progress_status === "6. Shortlisted" ||
                      person.assignment.progress_status ===
                        "7. Submitted to Client" ||
                      person.assignment.progress_status ===
                        "8. Client Interview" ||
                      person.assignment.progress_status === "9. Offer" ||
                      person.assignment.progress_status === "10. Placed" ? (
                        <div className="btn btn-primary py-0.5 px-2 rounded-md shadow-sm font-medium text-white bg-green-600 w-full text-center font-xxs">
                          {person.assignment.progress_status}
                          {
                          //Active
                          }
                        </div>
                      ) : person.assignment.progress_status ===
                          "X. Rejected by Candidate" ||
                        person.assignment.progress_status ===
                          "X. Rejected by Consultant" ||
                        person.assignment.progress_status ===
                          "X. Not Relevant" ||
                        person.assignment.progress_status ===
                          "X. Rejected by Client" ? (
                        <div className="btn btn-primary py-1 px-2 rounded-md shadow-sm font-medium text-white bg-[#dc1f1f] w-full text-center font-xxs">
                          {person.assignment.progress_status}
                          {
                          //Inactive
                          }
                        </div>
                      ) : person.assignment.progress_status ===
                        "X. Candidate on hold" ? (
                        <div className="btn btn-primary py-1 px-2 rounded-md shadow-sm font-medium text-white bg-[#ffdd1f] w-full text-center">
                          {person.assignment.progress_status}
                          {
                          //On Hold
                          }
                        </div>
                      ) : person.assignment.progress_status === null ? (
                        <div>None</div>
                      ) : null}
                    </td>
                    <td className="text-xxs">{person.email}</td>
                   {/*
                    <td className="text-xxs">{person.salary}</td>
                    <td className="text-xxs">{person.currency}</td>
                   */}
                   <td>
                      <button
                        onClick={() =>
                          handleClick(
                            person.email,
                            person.assignment.external_ref,
                            person.assignment.progress_notes,
                            person.assignment.progress_status,
                            person.assignment.assignment_name,
                            person.assignment.candidate_internal_comments,
                            person.assignment.notes?.description?.interview,
                            person.assignment.notes?.description?.screening,
                            person.assignment.job_level,
                            person.id
                          )
                        }
                        className="btn btn-primary py-2 px-4 rounded-md shadow-sm text-xs font-medium text-white bg-[#68FA6F] hover:bg-[#44FA57]"
                      >
                        Screen
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() => toggleCommentsModal(person)}
                        className="btn py-2 px-4 rounded-md shadow-sm text-xs font-medium text-black bg-yellow-400 hover:bg-yellow-500"
                      >
                        More
                      </button>
                    </td>
                    
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <section className="bg-white dark:bg-gray-900">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
              <div className="mx-auto max-w-screen-sm text-center">
                <p className="mb-4 text-3xl tracking-tight font-bold text-gray-900 md:text-4xl dark:text-white">
                  User Not Found in the Database or has No Assignments
                </p>
                <p className="mb-4 text-md font-light text-gray-500 dark:text-gray-400">
                  The user cannot be found in the database or has no
                  Assignments. Would you like to create a new user?
                </p>
                <button
                  onClick={CreateCandidateOnCLick}
                  className="bg-green-400 hover:bg-emerald-400 transition p-3 rounded-3xl"
                >
                  <FaPlus className="text-white text-lg" />
                </button>
              </div>
            </div>
          </section>
        )}
      </div>
      <PeoplePagination
        currentPageIndex={currentPage}
        changePageIndex={handlePaginationChange}
        totalPages={totalPages}
      />
      <div className="items-right">
        <button
          onClick={Handleback}
          className="w-1/4 float-right mt-5 mr-5  py-2 px-4 rounded-md shadow-sm text-sm font-medium text-white  bg-[#1EADE1] hover:bg-[#1FAEF9]"
        >
          Back
        </button>
        <button
          type="submit"
          onClick={handleLogout}
          className="w-1/4 float-right mt-5 mr-5  py-2 px-4 rounded-md shadow-sm text-sm font-medium text-white bg-[#F51E1E] hover:bg-[#F50501]"
        >
          Logout
        </button>
      </div>
    </div>
  );
};

export default PeopleResultsForm;
