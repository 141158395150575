import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "tippy.js/dist/tippy.css";
import axios from "axios";
import {
  FaUserAlt,
  FaArrowCircleRight,
  FaArrowAltCircleLeft,
  FaExpandArrowsAlt,
  FaExpand,
  FaCloudUploadAlt,
  FaPaperclip,
} from "react-icons/fa";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import useFetchUserContext from "../hooks/use-userInformation-context";
import { UserAuth } from "../contexts/AuthContext";
import DocumentHandler from "./DocumentHandler";
import ResultTab from "./ResultTab";
import DocumentViewer from "./__tests__/DocumentViewer";
import ElementMarker from "./__tests__/ElementMarker";

import {
  SpecialistLeaderExecutive,
  GdprConsent,
  stages,
  CurrentCurrency,
  NoticePeriods,
} from "../api/dropdown_list";

import MyDropdownlist from "../components/dropdownlist";
import MyDropdownlistInfo from "../components/dropdownlistinfo";

import { CandidatesTab } from "../components/candidates_tab";

const Results = () => {
  const navigate = useNavigate();
  const { APIdata, logout, CurrentPickedCan, ThisCanditate } = UserAuth();
  const { inveniasdocuments, currentUserID, fetchSingleInveniasDocument } =
    useFetchUserContext();

  if (!APIdata) {
    navigate("/search");
  }

  // initialize form data from api fetch

  let formData = {
    NewCanditateID: APIdata[0]?.id,
    NewFirstName: APIdata[0]?.first_name,
    NewLastName: APIdata[0]?.last_name,
    NewEmail: APIdata[0]?.email,
    NewPhoneNumber: APIdata[0]?.phone_number,
    //New Felds Start Here
    NewCurrentBonus:
      APIdata[0]?.current_bonus != null
        ? numberWithCommas(APIdata[0]?.current_bonus) // ib and invenias is using future bonus field
        : "",
    NewCurrentJobLatestJobTitle: APIdata[0]?.current_latest_job_title,
    NewCurrentPension: APIdata[0]?.current_pension,
    // new baseline salary
    NewCurrentSalary:
      APIdata[0]?.current_salary != null
        ? numberWithCommas(APIdata[0]?.current_salary)
        : "",
    NewCurrentPensionCal:
      APIdata[0]?.current_pension !== null &&
      APIdata[0]?.current_salary !== null
        ? (
            (APIdata[0]?.current_pension / APIdata[0]?.current_salary) *
            100
          ).toFixed(2)
        : "",
    NewJobFunction: APIdata[0]?.job_function,
    NewJobIndutry: APIdata[0]?.industry,
    NewCurrentInterview: CurrentPickedCan.interview || "",
    NewCurrentSreening: CurrentPickedCan.screening || "",
    NewAssigmentName: CurrentPickedCan.assignment_name || "",
    NEWExternal_ref: CurrentPickedCan.external_ref || "",
    NewFuturePension: APIdata[0]?.future_pension,
    NewFutureSalary:
      APIdata[0]?.future_salary != null
        ? numberWithCommas(APIdata[0]?.future_salary)
        : "",
    // Previous fields...
    NewCurrentCurrency: APIdata[0]?.bonus_currency || "",
    NewNoticePeriods: APIdata[0]?.notice_period || "",
    NewCurrentBenefits: APIdata[0]?.salary_notes || "",

    NewFuturePensionCal:
      APIdata[0]?.future_pension !== null && APIdata[0]?.future_salary !== null
        ? (APIdata[0]?.future_pension / APIdata[0]?.future_salary) * 100
        : "",
    NewFutureBonus:
      APIdata[0]?.future_bonus != null
        ? numberWithCommas(APIdata[0]?.future_bonus)
        : "",

    NewAssigments: APIdata[0]?.assignments[0]?.progress_status,
    NewConsentDate: APIdata[0]?.consent?.consent_status,
    NewStatusDisplayText: APIdata[0]?.consent?.status_display_text,
    NewProgressStatus:
      CurrentPickedCan.progress_status || APIdata[0]?.assignments[0]?.progress_status,
    NewProgressNote: CurrentPickedCan.progress_notes || "",
    NewFuturePosition:
      CurrentPickedCan.assignment_name ||
      APIdata[0]?.assignments[0]?.assignment_name,
    NewDocuments: inveniasdocuments,
    NewClientPortal: APIdata[0]?.client_portal || "",
    //New Felds Stop Here
    NewCurrently_employed: APIdata[0]?.currently_employed,
    NewCurrentLatestJobTitle: APIdata[0]?.current_latest_employer,
    new_start_date_job_started: APIdata[0]?.start_date_job_stated,
  };

  console.log("rendering results screen");

  // loading
  const [isLoading, setIsLoading] = useState(false);

  // local state (form)

  const [localFormData, setLocalFormData] = useState(formData);

  console.log(localFormData);
  // deconstruct local state

  useEffect(() => {
    setLocalFormData(formData);
  }, [APIdata]);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    } else {
      const delayInputTimeoutId = setTimeout(async () => {
        if (submitIsRunning) {
          return;
        }

        setSubmitIsRunning(true);

        submitInformation();
      }, 8500);
      return () => clearTimeout(delayInputTimeoutId);
    }
  }, [localFormData]);

  const [shortTermBonusUnit, setShortTermBonusUnit] = useState("DKK");

  const [currentPDFShow, setCurrentPDFShow] = useState("");

  const [isInitialRender, setIsInitialRender] = useState(true);

  //console.log(formData)

  const UpdateDocuments = (value) => {
    /*
    setFormData((prevFormData) => {
      return { ...prevFormData, NewDocuments: value?.Items }
    })
    */

    formData = { ...formData, NewDocuments: value?.Items };
  };

  const PickCurrentPDF = (value) => {
    //console.log(value);

    fetchSingleInveniasDocument(currentUserID, value[1], value[0]);

    setCurrentPDFShow(value);
  };

  const [toggleAppendix, setToggleAppendix] = useState(true);

  const ToggleAppendix = (NewToggleAppendixState) => {
    setToggleAppendix(NewToggleAppendixState);
  };

  const [togglePDF, setTogglePDF] = useState(true);

  const TogglePDF = (NewTogglePdfState) => {
    setTogglePDF(NewTogglePdfState);
  };

  //const [currentPDF, setCurrentPDF] = useState();

  function numberWithCommas(x) {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(",");
  }

  const [submitIsRunning, setSubmitIsRunning] = useState(false);

  const submitInformation = async (e) => {

    let event_type = ""

    if(e){
      event_type = e.target.type
    }

    
    if(event_type === 'submit'){
      toast.loading("Saving...", {
        theme: "colored",
        hideProgressBar: true,
      });
    }

    if (submitIsRunning) {
      toast.dismiss(); // Dismiss the loading toast
    
        toast.success("Saved", {
          theme: "colored",
          hideProgressBar: true,
          autoClose: 2000,
        }); 
      return
    }

    if (!localFormData.NewFutureSalary) {
      toast.dismiss(); // Dismiss the loading toast
      toast.error("Error - Type in a Baseline Salary", {
        theme: "colored",
        hideProgressBar: true,
        autoClose: 2000,
      });
    }

    setSubmitIsRunning(true);

    let future_bonus = localFormData.NewFutureBonus;

    if (shortTermBonusUnit === "%") {
      future_bonus =
        parseFloat(localFormData.NewFutureBonus) != null
          ? Math.ceil(
              (parseFloat(localFormData.NewFutureBonus) *
                parseInt(localFormData.NewFutureSalary.replace(".", ""))) /
                100
            )
          : "";

      future_bonus = numberWithCommas(future_bonus);
    }

    try {
      const RemappedAPI = {
        candidate_id: localFormData.NewCanditateID,
        email: localFormData.NewEmail,
        mobile_phone: localFormData.NewPhoneNumber,
        //current_position: formData.NewJobFunction,
        current_salary: localFormData.NewFutureSalary,
        current_pension: String(localFormData.NewFuturePensionCal)
          .replace(/%/g, "")
          .replace(/,/g, "."),
        salary_notes: localFormData.NewCurrentBenefits,
        current_bonus: future_bonus,
        currency: localFormData.NewCurrentCurrency,
        current_position: localFormData.NewCurrentJobLatestJobTitle,
        notice_period: localFormData.NewNoticePeriods,
        job_level: localFormData.NewJobFunction,
        future_position: localFormData.NewFuturePosition,
        future_salary: localFormData.NewFutureSalary,
        future_pension: String(localFormData.NewFuturePensionCal)
          .replace(/%/g, "")
          .replace(/,/g, "."),
        future_bonus: future_bonus,
        industry: localFormData.NewJobIndutry,
        progress_notes: localFormData.NewProgressNote,
        external_ref: localFormData.NEWExternal_ref,
        progress_status: localFormData.NewProgressStatus,
        screening_note:
          "SCREENING\n" +
          removeMatchingWord(localFormData.NewCurrentSreening, "SCREENING\n"),
        interview_note:
          "INTERVIEW\n" +
          removeMatchingWord(localFormData.NewCurrentInterview, "INTERVIEW\n"),
        client_portal: localFormData.NewClientPortal,
        documents: localFormData.NewDocuments,
      };

      console.log(RemappedAPI);

      await axios.post(
        //'https://europe-west3-compass-gcp.cloudfunctions.net/DeepCompass_Update_Candidates_Data-TEST',
        "https://europe-west3-compass-gcp.cloudfunctions.net/DeepCompass_Update_Candidates_Data",
        RemappedAPI
      );

      if(event_type === 'submit'){
        toast.dismiss(); // Dismiss the loading toast
    
        toast.success("Saved", {
          theme: "colored",
          hideProgressBar: true,
          autoClose: 2000,
        }); 
      }

      setSubmitIsRunning(false);
    } catch (error) {
      toast.dismiss(); // Dismiss the loading toast
      toast.error("Error", {
        theme: "colored",
        hideProgressBar: true,
        autoClose: 2000,
      });
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      console.log("Log Ud");
      navigate("/");
    } catch (e) {
      console.log(e.message);
    }
  };

  const goBack = async () => {
    ThisCanditate("");
    navigate("/search");
  };

  const handleDropDown = (selectedOption, name) => {
    setLocalFormData((prevState) => ({
      ...prevState,
      [name]: selectedOption.value,
    }));

    //formData = { ...formData, [name]: selectedOption.value }
  };

  const handleChange = (e) => {
    let value = e.target.value;

    if (e.target.name === "NewClientPortal") {
      value = value.replace(/<br\s*\/?>/gi, "\n\n").replace(/<[^>]+>/g, "");
      setLocalFormData((prevState) => ({
        ...prevState,
        NewClientPortal: value,
      }));
      return;
    }

    //console.log(value)

    //return
    setLocalFormData((prevState) => ({
      ...prevState,
      [e.target.name]: value,
    }));

    //formData = { ...formData, [e.target.name]: value }
  };

  const handleShortTermBonusChange = (e) => {
    let shortTermValue;

    let form_bonus = localFormData.NewFutureBonus;
    let form_salary = parseFloat(
      localFormData.NewFutureSalary.replace(".", "")
    );

    if (shortTermBonusUnit === "DKK") {
      setShortTermBonusUnit("%");

      form_bonus = form_bonus.replace(".", "").replace(",", ".");

      shortTermValue =
        parseFloat(form_bonus) != null
          ? (parseFloat(form_bonus) / form_salary) * 100
          : "";

      shortTermValue = shortTermValue.toLocaleString("de-DE");
    } else {
      setShortTermBonusUnit("DKK");

      form_bonus = form_bonus.replace(".", "").replace(",", ".");

      shortTermValue =
        parseFloat(form_bonus) != null
          ? (parseFloat(form_bonus) * form_salary) / 100
          : "";
    }

    shortTermValue = shortTermValue.toLocaleString("de-DE");

    setLocalFormData((prevState) => ({
      ...prevState,
      NewFutureBonus: shortTermValue,
    }));

    //formData = { ...formData, NewFutureBonus: shortTermValue }
  };

  function removeMatchingWord(str, word) {
    var firstThreeChars = str.slice(0, 3); // Extract the first three characters from the given string

    var wordFirstThreeChars = word.slice(0, 3); // Extract the first three characters from the word

    if (firstThreeChars === wordFirstThreeChars) {
      return str.replace(word, ""); // Remove the matching word from the string and return the updated string
    }

    return str; // Return the original string if no match is found
  }

  const [showColumn1, setShowColumn1] = useState(true);
  const [showColumn1State, setShowColumn1State] = useState(true);

  /*
  const [showColumn2, setShowColumn2] = useState(true)
  const [showColumn2State, setShowColumn2State] = useState(true)
  */

  const [showColumn3, setShowColumn3] = useState(false);
  const [showColumn3State, setShowColumn3State] = useState(false);

  const [showColumn2Expand, setShowColumnExpand2] = useState(false);
  const [showColumn3Expand, setShowColumnExpand3] = useState(false);

  const [expandEssential, setExpandEssential] = useState(false);
  const [expandScreening, setExpandScreening] = useState(false);

  const [expandHideEssential, setHideEssential] = useState(true);
  const [expandHideScreening, setHideScreening] = useState(true);

  const handleEssentialClick = () => {
    setHideScreening((prevState) => !prevState);
    setExpandEssential((prevState) => !prevState);
  };

  const handleScreeningClick = () => {
    setHideEssential((prevState) => !prevState);
    setExpandScreening((prevState) => !prevState);
  };

  const handleLeftClick = () => {
    setShowColumn1(true);
    setShowColumn3(false);
    setShowColumn1State(true);
    setShowColumn3State(false);
  };

  const handleRightClick = () => {
    setShowColumn1(false);
    setShowColumn3(true);

    setShowColumn1State(false);
    setShowColumn3State(true);
  };

  const handleExpandMiddleClick = () => {
    if (showColumn1State) {
      setShowColumn1((prevState) => !prevState);
    }

    if (showColumn3State) {
      setShowColumn3((prevState) => !prevState);
    }
    setShowColumnExpand2((prevState) => !prevState);
  };

  const handleExpandRightClick = () => {
    setShowColumnExpand3((prevState) => !prevState);
  };

  return (
    <div className="flex flex-col max-w-7xl min-w-4x1 mx-auto">
      {/*
      <CandidatesTab
        ExternalRef={CurrentPickedCan.external_ref}
        currentCandidateId={formData.NewCanditateID}
        setIsLoading={setIsLoading}
        setLocalFormData={setLocalFormData}
      />
      */}
      {isLoading ? (
        <div className="loader"></div> // Replace with your loader design
      ) : (
        <div
          id="printableContent"
          className="flex flex-col max-w-7xl min-w-4x1 mx-auto print-body pass-title"
        >
          <div className="bg-white sm:rounded-lg pb-16 custom-shadow mb-10">
            <div className="border-t border-gray-200">
              <div class="grid grid-cols-12 mb-12">
                <div className="flex text-left py-4 px-8 border-[#1eade1] col-span-8">
                  <span className="flex w-auto h-10 py-2 mr-2 text-md">
                    <FaUserAlt className="text-[#1eade1] mt-1 mr-2" />
                    <span>
                      {localFormData.NewFirstName} {localFormData.NewLastName}
                    </span>
                    <span className="mx-2 border-r-2 border-sky-400"></span>
                    <ElementMarker
                      name="NewEmail"
                      value={formData.NewEmail}
                      onChange={handleChange}
                    />
                    <span className="mx-2 border-r-2 border-sky-400"></span>
                    <ElementMarker
                      name="NewPhoneNumber"
                      value={formData.NewPhoneNumber}
                      onChange={handleChange}
                    />
                  </span>
                </div>
                <ResultTab onToggle={ToggleAppendix} PrintFormData={formData} />
              </div>
              <dl>
                <div className="h-9 w-full pb-16 grid grid-cols-12">
                  <div className="col-span-6"></div>
                  <div className="col-span-6 grid grid-cols-12">
                    <div className="col-span-4 mr-2">
                      <input
                        className="h-10 p-4 w-full input-result"
                        name="NewProgressNote"
                        type="text"
                        placeholder="Progress Notes"
                        value={localFormData.NewProgressNote}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                    </div>
                    <div className="col-span-4 mr-2">
                      <MyDropdownlist
                        className="text-xs"
                        options={stages}
                        name="NewProgressStatus"
                        defaultOption={localFormData.NewProgressStatus}
                        onChange={(selectedValue) => {
                          handleDropDown(selectedValue, "NewProgressStatus");
                        }}
                      />
                    </div>
                    <div className="col-span-4 mr-2">
                      <MyDropdownlistInfo
                        Isdisable={true}
                        name="new_target_canditat"
                        options={GdprConsent}
                        defaultText={formData.NewConsentDate}
                        defaultOption={formData.NewConsentDate}
                      />
                    </div>
                  </div>
                </div>
                {toggleAppendix && (
                  <div
                    className="flex whitespace-no-wrap"
                    style={{ width: "100%" }}
                  >
                    <div
                      style={{
                        width: expandEssential
                          ? "100%"
                          : expandHideEssential
                          ? "50%"
                          : "0",
                        transition: "width 0.5s",
                      }}
                      className={`inline-block transition-all ease-in-out duration-500 ${
                        expandHideEssential ? "" : "overflow-hidden"
                      }`}
                    >
                      <div className="grid grid-cols-12">
                        <div
                          className="col-span-12 bg-sky-500 text-white px-5 py-2 text-lg"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>Essential</span>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <button onClick={handleEssentialClick}>
                              {expandEssential ? (
                                <FaExpand className="text-xl" />
                              ) : (
                                <FaExpandArrowsAlt className="text-xl" />
                              )}
                            </button>
                          </div>
                        </div>
                        <div className="col-span-12 px-6 py-4 bg-gray-100 border-b-2 border-gray-200 text-gray-600">
                          <span className="font-bold">Current</span>
                        </div>
                        <div className="px-1 col-span-6">
                          <div className="col-span-12 px-2 pl-5 pt-3">
                            <dt className="text-xs text-search font-small text-black col-span-2">
                              Applied Company
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 col-span-4">
                              <input
                                className="h-7 px-4 py-5 w-full input-result border-r-2"
                                name="NewFuturePosition"
                                value={localFormData.NewFuturePosition}
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              />
                            </dd>
                          </div>
                        </div>

                        <div className="px-1 col-span-6">
                          <div className="col-span-12 px-2 pl-2 pt-3">
                            <dt className="text-xs text-search font-small text-black col-span-2">
                              Currency
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 col-span-4">
                              <MyDropdownlist
                                options={CurrentCurrency}
                                name="NewCurrentCurrency"
                                defaultOption={localFormData.NewCurrentCurrency}
                                onChange={(selectedValue) => {
                                  handleDropDown(
                                    selectedValue,
                                    "NewCurrentCurrency"
                                  );
                                }}
                              />
                            </dd>
                          </div>
                        </div>

                        <div className="px-1 col-span-6">
                          <div className="col-span-12 px-2 pl-5 pt-3">
                            <dt className="text-xs text-search font-small text-black col-span-2">
                              Position
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 col-span-4">
                              <input
                                className="h-7 p-4 w-full input-result border-r-2"
                                name="NewCurrentJobLatestJobTitle"
                                value={
                                  localFormData.NewCurrentJobLatestJobTitle
                                }
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                              />
                            </dd>
                          </div>
                        </div>

                        <div className="px-1 col-span-6">
                          <div className="col-span-12 px-2 pt-3">
                            <dt
                              className="text-xs text-search font-small text-black  col-span-12"
                              id="CurrentSalary"
                            >
                              Baseline Salary
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 col-span-12">
                              <input
                                className="h-7 p-4 w-full input-result"
                                name="NewFutureSalary"
                                value={localFormData.NewFutureSalary}
                                onInput={(e) => {
                                  const inputValue = e.target.value;
                                  const numericValue = inputValue.replace(
                                    /[^0-9.]/g,
                                    ""
                                  ); // Remove non-numeric and non-dot characters
                                  e.target.value = numericValue;
                                  handleChange(e);
                                }}
                              />
                            </dd>
                          </div>
                        </div>

                        <div className="px-1 col-span-6">
                          <div className="col-span-12 px-2 pl-5 pt-3">
                            <dt
                              className="text-xs text-search font-small text-black col-span-12"
                              id="Pension"
                            >
                              Pension *
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 col-span-12">
                              <div className="input-wrapper">
                                <input
                                  className="h-7 p-4 w-full input-result"
                                  name="NewFuturePensionCal"
                                  value={localFormData.NewFuturePensionCal}
                                  onChange={(e) => handleChange(e)}
                                />
                              </div>
                            </dd>
                          </div>
                        </div>

                        <div className="px-1 col-span-6">
                          <div className="col-span-2 px-2 pt-3">
                            <dt
                              className="text-xs text-search font-small text-black col-span-2"
                              id="bonsus"
                            >
                              Short Term Bonus (DKK or %)
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 col-span-4 flex">
                              <div
                                className="short-bonus-input-wrapper"
                                data-after={shortTermBonusUnit}
                              >
                                <input
                                  className="h-7 p-4 w-full input-result"
                                  name="NewFutureBonus"
                                  placeholder=""
                                  value={localFormData.NewFutureBonus}
                                  type="text"
                                  onInput={(e) => {
                                    const inputValue = e.target.value;
                                    const numericValue = inputValue.replace(
                                      /[^0-9.]/g,
                                      ""
                                    ); // Remove non-numeric and non-dot characters
                                    e.target.value = inputValue;
                                    handleChange(e);
                                  }}
                                />
                              </div>

                              <label class="switch">
                                <input
                                  type="checkbox"
                                  onClick={handleShortTermBonusChange}
                                />
                                <span class="slider round"></span>
                              </label>
                            </dd>

                            <div></div>
                          </div>
                        </div>

                        <div className="px-1 col-span-12">
                          <div className="col-span-12 pl-5 pr-2 pt-3">
                            <dt className="text-xs text-search font-small text-black  col-span-2">
                              ¨Short/Long Term Incentives (Salary Expectations,
                              Company Car, Car Allowance, etc)
                            </dt>
                            <dd className="mt-2 text-sm text-gray-900 col-span-4">
                              <textarea
                                className="h-17 p-4 w-full input-result"
                                name="NewCurrentBenefits"
                                value={localFormData.NewCurrentBenefits}
                                onChange={(e) => handleChange(e)}
                              >
                                At w3schools.com you will learn how to make a
                                website. They offer free tutorials in all web
                                development technologies.
                              </textarea>
                            </dd>
                          </div>
                        </div>

                        <div className="px-1 col-span-12">
                          <div className="col-span-12 pl-5 pr-2 pt-3">
                            <dt className="text-xs text-search font-small text-black  col-span-2">
                              Talent Bank
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 col-span-4">
                              <MyDropdownlist
                                options={SpecialistLeaderExecutive}
                                name="NewJobFunction"
                                defaultOption={localFormData.NewJobFunction}
                                onChange={(selectedValue) => {
                                  handleDropDown(
                                    selectedValue,
                                    "NewJobFunction"
                                  );
                                }}
                              />
                            </dd>
                          </div>
                        </div>

                        <div className="px-1 col-span-12">
                          <div className="col-span-12 pl-5 pr-2 pt-3">
                            <dt className="text-xs text-search font-small text-black  col-span-2">
                              Warning Notices
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 col-span-4">
                              <MyDropdownlist
                                options={NoticePeriods}
                                name="NewNoticePeriods"
                                defaultOption={localFormData.NewNoticePeriods}
                                onChange={(selectedValue) => {
                                  handleDropDown(
                                    selectedValue,
                                    "NewNoticePeriods"
                                  );
                                }}
                              />
                            </dd>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        width: expandScreening
                          ? "100%"
                          : expandHideScreening
                          ? "50%"
                          : "0",
                        transition: "width 0.5s",
                        position: "relative",
                      }}
                      className={`inline-block transition-all ease-in-out duration-500 overflow-hidden`}
                    >
                      <div
                        className="col-span-12 bg-sky-600 text-white px-5 py-2 text-lg absolute w-full"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>Screening</span>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <button onClick={handleScreeningClick}>
                            {expandScreening ? (
                              <FaExpand className="text-xl" />
                            ) : (
                              <FaExpandArrowsAlt className="text-xl" />
                            )}
                          </button>
                        </div>
                      </div>

                      <textarea
                        className="defaulttextfield"
                        style={{ width: "100%", marginTop: "42px" }}
                        name="NewCurrentSreening"
                        type="text"
                        onChange={(e) => handleChange(e)}
                        spellcheck="true"
                      >
                        {removeMatchingWord(
                          formData.NewCurrentSreening,
                          "SCREENING\n"
                        )}
                      </textarea>
                    </div>
                  </div>
                )}
              </dl>
            </div>
          </div>

          <div className="bg-white sm:rounded-lg pb-8 custom-shadow mb-10 relative">
            <div class="grid grid-cols-12 mb-2">
              <div className="flex text-left py-4 px-8 border-[#1eade1] col-span-8">
                <span className="flex w-auto h-10 py-2 mr-2 text-lg">
                  <FaPaperclip className="text-[#1eade1] mt-1.5 mr-2" />
                  Documents & Notes
                </span>
              </div>
              <ResultTab onToggle={TogglePDF} />
            </div>
            {togglePDF && (
              <div>
                <div
                  className="flex overflow-x-auto whitespace-no-wrap border-b"
                  style={{ width: "100%", height: "100vh" }}
                >
                  <div
                    style={{
                      width: showColumn1 ? "50%" : "0",
                      transition: "width 0.5s",
                    }}
                    className={`inline-block transition-all ease-in-out duration-500 overflow-hidden`}
                  >
                    <div
                      className="w-full bg-sky-600 text-white px-5 py-2 text-lg"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>Document Preview</span>
                      <button
                        onClick={handleRightClick}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <FaArrowAltCircleLeft className="text-xl" />
                      </button>
                    </div>
                    <DocumentViewer
                      DocumentInfo={currentPDFShow}
                      className="pdf-holder-child mx-2"
                    />
                  </div>

                  <div
                    style={{
                      width: showColumn2Expand
                        ? "100%"
                        : showColumn3Expand
                        ? "0"
                        : "50%",
                      transition: "width 0.5s",
                    }}
                    className={"inline-block  overflow-hidden relative"}
                  >
                    <div
                      className="w-full bg-sky-500 text-white px-5 py-2 text-lg absolute"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>Interview</span>
                      <button
                        onClick={handleExpandMiddleClick}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {showColumn2Expand ? (
                          <FaExpand className="text-xl" />
                        ) : (
                          <FaExpandArrowsAlt className="text-xl" />
                        )}
                      </button>
                    </div>
                    <textarea
                      className="defaulttextfield"
                      style={{ width: "100%", marginTop: "42px" }}
                      name="NewCurrentInterview"
                      type="text"
                      onChange={(e) => handleChange(e)}
                    >
                      {removeMatchingWord(
                        formData.NewCurrentInterview,
                        "INTERVIEW\n"
                      )}
                    </textarea>
                  </div>

                  <div
                    style={{
                      width: showColumn3Expand
                        ? "100%"
                        : showColumn3
                        ? "50%"
                        : "0",
                      transition: "width 0.5s",
                    }}
                    className={
                      "inline-block transition-all ease-in-out duration-500 overflow-hidden relative"
                    }
                  >
                    <div
                      className="w-full bg-sky-600 text-white px-5 py-2 text-lg absolute"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <span>Client Portal</span>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <button onClick={submitInformation} className="mr-3">
                          <FaCloudUploadAlt className="text-xl" />
                        </button>

                        <button
                          onClick={handleExpandRightClick}
                          className="mr-3"
                        >
                          {showColumn3Expand ? (
                            <FaExpand className="text-xl" />
                          ) : (
                            <FaExpandArrowsAlt className="text-xl" />
                          )}
                        </button>

                        <button onClick={handleLeftClick}>
                          <FaArrowCircleRight className="text-xl" />
                        </button>
                      </div>
                    </div>
                    {/*
          

            <ReactQuill
                      className="defaulttextfield"
                      style={{ width: "100%", marginTop: "42px" }}
                      value={localFormData.NewClientPortal || ""}
                      onChange={(value) =>
                        handleChange({
                          target: { name: "NewClientPortal", value },
                        })
                      }
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, false] }],
                          ["bold", "italic", "underline"],
                          [],
                          [{ list: "ordered" }, { list: "bullet" }],
                          ["clean"],
                        ],
                      }}
                      placeholder="Type here..."
                    />
        */}

                    <textarea
                      className="defaulttextfield"
                      style={{ width: "100%", marginTop: "42px" }}
                      name="NewClientPortal"
                      type="text"
                      onChange={(e) => handleChange(e)}
                    >
                      {localFormData.NewClientPortal ? (localFormData.NewClientPortal).replace(
                        /<br\s*\/?>/gi,
                        "\n\n"
                      ).replace(/<[^>]+>/g, "") : ""}
                    </textarea>
                  </div>
                </div>
                <div className="float-right w-1/2 py-2">
                  <DocumentHandler
                    PickCurrentPDF={PickCurrentPDF}
                    UpdateDocuments={UpdateDocuments}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="items-right">
            <button
              type="submit"
              onClick={submitInformation}
              className="w-1/4 mt-5 float-right py-2 px-2 rounded-md shadow-sm text-sm font-medium text-white bg-[#68FA6F] hover:bg-[#44FA57]"
            >
              Submit
            </button>
            <button
              onClick={goBack}
              className="w-1/4 float-right mt-5 mr-5  py-2 px-2 rounded-md shadow-sm text-sm font-medium text-white  bg-[#1EADE1] hover:bg-[#1FAEF9]"
            >
              Back
            </button>
            <button
              type="submit"
              onClick={handleLogout}
              className="w-1/4 float-right mt-5 mr-5  py-2 px-2 rounded-md shadow-sm text-sm font-medium text-white bg-[#F51E1E] hover:bg-[#F50501]"
            >
              Logout
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Results;
